import { IS_DEVELOPMENT } from "shared-utils";

export const FEDERATED_TYK_API_URL =
  process.env.NEXT_PUBLIC_FEDERATED_TYK_URL ||
  "https://gtp-tyk-sa.eproc.dev/graphql/";

export const GRAPHQL_URL = IS_DEVELOPMENT
  ? "https://account.eproc.dev/graphql"
  : `${process.env.NEXT_PUBLIC_ACCOUNT_URL}graphql`;

export const FEDERATED_TYK_WS_URL = FEDERATED_TYK_API_URL.replace(
  "https",
  "wss"
);

export const AUTH = {
  DOMAIN:
    process.env.NEXT_PUBLIC_AUTH_DOMAIN || "https://auth-staging.eproc.dev",
  CLIENT_ID: process.env.AUTH_CLIENT_ID || "",
  AUDIENCE: process.env.AUTH_AUDIENCE || "",
  CLIENT_SECRET: process.env.AUTH_CLIENT_SECRET || "",
  NEXTAUTH_SECRET: process.env.NEXTAUTH_SECRET || "",
  REDIRECT_SECRET: process.env.NEXT_PUBLIC_REDIRECT_SECRET || "",
  TOKEN_CLAIM_PUBLIC_KEY: process.env.AUTH_TOKEN_CLAIM_PUBLIC_KEY || "",
  TOKEN_CLAIM_PRIVATE_KEY: process.env.AUTH_TOKEN_CLAIM_PRIVATE_KEY || "",
};
