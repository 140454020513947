export const PROFESSIONS = ["PROVIDER", "NON_PROVIDER"] as const;

export const PROFESSION_ITEMS: Array<{
  label: string;
  value: (typeof PROFESSIONS)[number];
  description: string;
  id: string;
}> = [
  {
    label: "Non Penyedia",
    value: "NON_PROVIDER",
    id: "non-provider-radio",
    description:
      "Pengguna yang memiliki kewenangan anggaran sehingga dapat melaksanakan pengadaan barang dan jasa. Termasuk ASN, TNI, Polri, pelaku usaha dan pegawai pemerintahan.",
  },
  {
    label: "Penyedia",
    value: "PROVIDER",
    id: "non-provider-radio",
    description:
      "Pelaku usaha yang menawarkan barang atau jasa berdasarkan kontrak, seperti penyediaan barang, konstruksi, dan jasa lainnya.",
  },
];

export const FINISH_STATUS = [
  "UPLOAD_VALIDATION_FAILED",
  "UPLOAD_VIRUS_DETECTED",
  "UPLOAD_SUCCESS",
];

export const ENV = process.env.NEXT_PUBLIC_ENV || "development";

export const isLocal = ENV === "development";
export const isStaging = ENV === "staging";
export const isPreProduction = ENV === "preproduction";
export const isProduction = ENV === "production" || isPreProduction;

export const FORBIDDEN_PAGES_ON_PREPRODUCTION = ["delegation", "questionnaire"];

export const TURNSTILE_SECRET_KEY_STEP_ONE =
  process.env.TURNSTILE_SECRET_KEY_STEP_ONE;
export const TURNSTILE_SECRET_KEY_STEP_TWO =
  process.env.TURNSTILE_SECRET_KEY_STEP_TWO;

export const TURNSTILE_SECRET_KEY = {
  1: TURNSTILE_SECRET_KEY_STEP_ONE,
  2: TURNSTILE_SECRET_KEY_STEP_TWO,
};

export const ZENDESK_ACCOUNT_REQUEST_TICKET_URL =
  `${process.env.NEXT_PUBLIC_ZENDESK_ACCOUNT_URL}/requests/new` || "";

export const publicPages = [
  "/auth/signin",
  "/auth/signout",
  "/auth/error",
  "/favicon.ico",
];

export const COMPANY_NAME_MAX_LENGTH = 255;

export const MONITORED_QUERIES_MUTATIONS = ["updateVerificationProcess"];
