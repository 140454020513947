"use client";

import { cx } from "class-variance-authority";
import Image from "next/image";
import { notFound, useRouter, useSearchParams } from "next/navigation";
import { type Session } from "next-auth";
import { useLocale } from "next-intl";
import LogoInaproc from "shared-assets/icons/logo-inaproc.webp";
import { EN, ID } from "shared-config/locales";

import NotificationSection from "./components/NotificationSection";
import UserDropdown from "./components/UserDropdown";

import { HIDE_NAVBAR_CONTENT_PATHS } from "./constants";
import {
  FORBIDDEN_PAGES_ON_PREPRODUCTION,
  isPreProduction,
  isProduction,
} from "@/constant";
import { Link, usePathname } from "@/navigation";

type THeader = {
  session?: Session | null;
};

const Header = ({ session }: THeader) => {
  const pathname = usePathname();
  const router = useRouter();
  const searchParam = useSearchParams();
  const href = searchParam?.toString()
    ? `${pathname}?${searchParam?.toString()}`
    : pathname;
  const locale = useLocale();
  const shouldRenderNavbarContent = !HIDE_NAVBAR_CONTENT_PATHS.some((path) =>
    pathname?.includes(path)
  );

  if (
    (isPreProduction || isProduction) &&
    FORBIDDEN_PAGES_ON_PREPRODUCTION.some((path) => pathname?.includes(path))
  ) {
    notFound();
  }

  return (
    <div
      className="flex h-[92px] w-full items-center justify-between px-11"
      style={{
        boxShadow: "inset 0px -1px 0px #EBEBED",
      }}
    >
      <div>
        <Image
          src={LogoInaproc}
          alt="logo inaproc"
          width={82}
          height={28}
          className="cursor-pointer"
          onClick={() => router.push("/")}
        />
      </div>
      {shouldRenderNavbarContent && (
        <div className="flex items-center">
          {!isPreProduction && !isProduction && (
            <>
              <div className="pr-2">
                <Link
                  locale={EN}
                  href={href}
                  className={cx(locale === EN && "font-bold")}
                >
                  EN
                </Link>{" "}
                /{" "}
                <Link
                  locale={ID}
                  href={href}
                  className={cx(locale === ID && "font-bold")}
                >
                  ID
                </Link>
              </div>
              <div className="pr-2">
                <NotificationSection />
              </div>
            </>
          )}

          <UserDropdown session={session} />
        </div>
      )}
    </div>
  );
};

export default Header;
