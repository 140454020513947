import { NotificationModal, useNotificationByFeed } from "notifications";
import { useState } from "react";
import { Bell } from "react-feather";
import { Dropdown, DropdownItem } from "shared-ui";

const NotificationSection = () => {
  const { unseenCount, notifications } = useNotificationByFeed(undefined);
  const [openModalNotification, setOpenModalNotification] = useState(false);

  const handleModalNotification = (open: boolean) => {
    setOpenModalNotification(open);
  };

  const countNotification =
    notifications.length && unseenCount && unseenCount >= 10
      ? "9+"
      : unseenCount;

  return (
    <>
      <Dropdown
        trigger={
          <div
            className="relative cursor-pointer"
            onClick={() => setOpenModalNotification(!openModalNotification)}
          >
            <Bell size={32} color="#344054" />
            {Boolean(countNotification) && (
              <div className="absolute -right-1 -top-2 h-[21px] min-w-[21px] rounded-full border-2 border-primary25 bg-primary400 px-1 text-center text-xs text-primary25">
                {countNotification}
              </div>
            )}
          </div>
        }
        onOpenChange={() => handleModalNotification(false)}
        open={openModalNotification}
      >
        <DropdownItem>
          <NotificationModal
            isOpen={openModalNotification}
            onClose={() => handleModalNotification(false)}
          />
        </DropdownItem>
      </Dropdown>
    </>
  );
};

export default NotificationSection;
