import {
  IS_PRODUCTION,
  IS_PREPRODUCTION,
  IS_CANARY_PREPROD,
} from "shared-utils";

export const SHOW_PPSDM_FIELD = "ACCOUNT-SHOW_PPSDM_FIELD";

export const SHOW_NON_INTEGRATION_SIRUP =
  "ACCOUNT-KILL_SWITCH_INTEGRATION_SIRUP";

export const SHOW_SOCKET_GET_UPLOAD_STATUS_V1 =
  "ACCOUNT-KILL_SWICTH_AUTH_UPLOAD_STATUS_SOCKET";

export const SHOW_INTEGRATE_STAFF = "ACCOUNT-STAFF_GRADUAL_RELEASE";

export const USE_GOOGLE_MAP_VERSION_2 = "USE_GOOGLE_MAP_VERSION_2";

export const SHOW_PINPOINT_GOOGLE_MAP_V2 = "ACCOUNT-PINPOINT_GOOGLE_MAP_V2";

export const SHOW_OMNYX_NEW_FLOW = "ACCOUNT-NEW_NON_PENYEDIA_OMNYX_FLOW";

export const SHOW_OMNYX_NEW_PROFILE_FLOW =
  "ACCOUNT-NEW_PROFILE_NON_PENYEDIA_OMNYX_FLOW";

export const WHITELIST_KLPD_VAI = "ACCOUNT-WHITELIST_KLPD_VAI";

export const OPEN_ALL_KLPD = "ACCOUNT-OPEN_ALL_KLPD";

export const FLAG_SCHEMA_PRIVY_REG_LINK_ACCOUNT =
  !IS_PRODUCTION || IS_CANARY_PREPROD;

export const FLAG_DUPLICATE_PENDING_PERSONA_STATUS =
  !IS_PRODUCTION || IS_PREPRODUCTION;

export const FLAG_ADD_ADMIN_PPE_ADMIN_RUP = !IS_PRODUCTION || IS_CANARY_PREPROD;

export const FLAG_HIDE_DATA_PERUSAHAAN = !IS_PRODUCTION || IS_CANARY_PREPROD;

export const FLAG_NEW_COMPONENT_AVATAR = !IS_PRODUCTION || IS_CANARY_PREPROD;

export const FLAG_VALIDASI_KSWP_FROM_DJP = !IS_PRODUCTION;

export const FLAG_SHOW_PERSONA_NON_KLPD = !IS_PRODUCTION;

export const FLAG_BIDANG_INDUSTRI_OPTIONS_ID = !IS_PRODUCTION;
