import getInitials from "./getInitials"

type TOptionsImage = {
  width: number
  height: number
}

// TODO: Remove this when FLAG_NEW_COMPONENT_AVATAR is not used
const generateInitialImageUrl = (fullName: string, options?: TOptionsImage) => {
  const width = options?.width.toString() || "100"
  const height = options?.height.toString() || "100"

  return `https://via.placeholder.com/${width}X${height}/?text=${getInitials(
    fullName
  )}`
}

export default generateInitialImageUrl
