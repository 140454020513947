const getInitials = (fullName?: string) => {
  if (!fullName) return ""
  return fullName
    .split(" ")
    .map((word) => word.charAt(0))
    .join("")
    .toUpperCase()
    .slice(0, 2)
}

export default getInitials
